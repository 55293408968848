@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  scroll-behavior: smooth;
}

.hero {
  background-size: cover;
  background-position: center;
}

.bg-purple {
  background-color: #800080;
}
.font-purple {
  color: #800080;
}
.bg-grey {
  background-color: #7b7b7b;
}
/* styles.css */
.gradient-border {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 4px;
  border-image-source: linear-gradient(to top, #cc208e 0%, #6713d2 100%);
}
